import React, { useState } from 'react';
import './App.css';

type Status = "idle" | "loading" | "success" | "error"

function App() {
  const [status, setStatus] = useState<Status>("idle")
  const [input, setInput] = useState<string>("")
  const [cityData, setCityData] = useState<any>({})
  const [errors, setErrors] = useState<string>("");

  async function fetchGET(url: string): Promise<void> {
    setStatus("loading")
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(
                `Unable to Fetch Data, Please check URL
                or Network connectivity!!`
            );
        }
        const data = await response.json();
        console.log('data', data)
        setCityData(data)
        setStatus("success");
    } catch (error: unknown) {
      console.error('Some Error Occured:', error);
      if (error instanceof Error) {
          setStatus("error");
          setErrors(error.message);
      } else {
        setErrors("An unknown error occurred");
      }
      setCityData(null);
    }
  }

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const fetchUrl = `https://fetch-xenprices.amcp.workers.dev/?city=${input}`
    fetchGET(fetchUrl)
  }

  return (
    <div className="App">
      <header className="App-header">
        <form onSubmit={submitForm}>
          <input 
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        </form>
        {status === 'loading' && <div>Fetching weather...</div>}
        {status === 'success' && cityData && (
          <div>
            <h3>Weather in {cityData.location.name}</h3>
            <p>{cityData.current.condition.text}</p>
            <img src={cityData.current.condition.icon} alt="weather icon" />
            <p>{cityData.current.temp_c}°C</p>
          </div>
        )}
        {status === 'error' && <div>Error: {errors}</div>}
      </header>
    </div>
  );
}

export default App;
